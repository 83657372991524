import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HoverCircleButton = _resolveComponent("HoverCircleButton")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.transparent ? 'transparent' : '', 'backdrop']),
    onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBackdropClick && _ctx.onBackdropClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['modal', _ctx.small ? 'modal-sm' : '']),
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createVNode(_component_HoverCircleButton, {
        class: "close-form",
        icon: "fa-xmark",
        onClick: _ctx.onBackdropClick
      }, null, 8, ["onClick"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 34)
  ], 34)), [
    [_vShow, _ctx.showModal]
  ])
}