import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3145d2ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hover-circle-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(['fa-solid', _ctx.icon])
    }, null, 2)
  ]))
}