
import { defineComponent } from "vue";
import HoverCircleButton from "./buttons/HoverCircleButton.vue";

export default defineComponent({
  name: "ContentModal",
  components: { HoverCircleButton },
  emits: ["backdrop-click"],
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    onBackdropClick() {
      if (this.closeOnBackdropClick) {
        this.showModal = false;
      }
      this.$emit("backdrop-click");
    },
  },
});
