
import { defineComponent, PropType } from "vue";
import dayjs from "dayjs";
import { Note } from "../types/custom-types.js";

export default defineComponent({
  name: "NoteItem",
  props: {
    note: {
      type: Object as PropType<Note>,
      required: true,
    },
  },
  computed: {
    lastUpdated() {
      return dayjs(this.note.lastUpdated).format("M/DD/YY");
    },
    previewText() {
      // only show first line if text contains line break
      const newline = this.note.text.indexOf("\n");
      return newline === -1 ? this.note.text : this.note.text.slice(0, newline);
    },
  },
  methods: {
    onClick() {
      this.$router.push(`${this.note.id}`);
    },
  },
});
