import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1833bfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notes-list" }
const _hoisted_2 = { class: "no-notes" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoteItem = _resolveComponent("NoteItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.filteredNotes.length === 0 && _ctx.notes.list.length > 0
          ? "no notes found"
          : ""), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pinnedNotes, (note) => {
      return (_openBlock(), _createBlock(_component_NoteItem, {
        key: note.id,
        note: note
      }, null, 8, ["note"]))
    }), 128)),
    (_ctx.pinnedNotes.length && _ctx.unpinnedNotes.length)
      ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unpinnedNotes, (note) => {
      return (_openBlock(), _createBlock(_component_NoteItem, {
        key: note.id,
        note: note
      }, null, 8, ["note"]))
    }), 128))
  ]))
}