
import { defineComponent } from "vue";

export default defineComponent({
  name: "HoverCircleButton",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
});
