
import { defineComponent } from "vue";
import NoteForm from "../components/NoteForm.vue";
import ContentModal from "../components/ContentModal.vue";

export default defineComponent({
  name: "AddNoteView",
  components: { NoteForm, ContentModal },
  data() {
    return {
      showExitModal: false,
    };
  },
  methods: {
    backToAddForm() {
      this.showExitModal = false;
    },
    confirmClose() {
      if (
        // text in form, ask to discard changes
        (document.querySelector("#title") as HTMLInputElement).value ||
        (document.querySelector("#text") as HTMLTextAreaElement).value
      ) {
        this.showExitModal = true;
      } else {
        // form empty, exit form
        this.discardNote();
      }
    },
    discardNote() {
      this.$router.push("/");
    },
  },
});
