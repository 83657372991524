
import { defineComponent, PropType } from "vue";
import { FontAwesomeIconButton } from "../../types/custom-types.js";

export default defineComponent({
  name: "IconButton",
  props: {
    btn: {
      type: Object as PropType<FontAwesomeIconButton>,
      required: true,
    },
  },
});
