
import { defineComponent } from "vue";
import dayjs from "dayjs";
import ContentModal from "../components/ContentModal.vue";
import IconButton from "../components/buttons/IconButton.vue";
import NoteForm from "../components/NoteForm.vue";
import { Note } from "../types/custom-types.js";
import { notes } from "../notes";

export default defineComponent({
  name: "NoteDetailView",
  components: { ContentModal, IconButton, NoteForm },
  emits: [],
  data() {
    return {
      isEditing: false,
      showDeleteModal: false,
      showExitModal: false,
      buttons: {
        pin: {
          text: "pin",
          class: "",
          icon: "fa-thumbtack",
        },
        unpin: {
          text: "unpin",
          class: "btn-orange",
          icon: "fa-thumbtack",
        },
        edit: {
          text: "edit",
          class: "btn-blue",
          icon: "fa-pencil",
        },
        delete: {
          text: "delete",
          class: "btn-red",
          icon: "fa-trash-can",
        },
        save: {
          text: "save",
          class: "btn-green",
          icon: "fa-check",
        },
      },
    };
  },
  created() {
    if (!notes.list.length) {
      notes.getLocalStorageNotes();
    }
    if (
      !notes.list
        .map((note: Note) => note.id)
        .includes(Number(this.$route.params.id))
    ) {
      // id/note does not exist in list
      this.$router.push("/");
    }
  },
  computed: {
    noteData() {
      const note = notes.list.find(
        (note: Note) => note.id === Number(this.$route.params.id)
      );
      return note;
    },
    lastUpdated() {
      if (this.noteData) {
        return dayjs(this.noteData.lastUpdated).format("M/DD/YY hh:mm a");
      } else {
        return "";
      }
    },
  },
  methods: {
    backToEditing() {
      this.showDeleteModal = false;
      this.showExitModal = false;
    },
    confirmClose() {
      if (this.isEditing) {
        this.showExitModal = true; // ask user if they want to exit without saving
      } else {
        this.$router.push("/");
      }
    },
    confirmDelete() {
      this.showDeleteModal = true;
    },
    deleteNote() {
      notes.deleteNote(Number(this.$route.params.id));
      this.$router.push("/");
    },
    discardChanges() {
      this.$router.push("/");
    },
    focusInput(e: Event) {
      (e.target as HTMLElement).focus();
      this.isEditing = true;
    },
    submitForm() {
      if (
        // title and/or text actually changed
        (document.querySelector("#title") as HTMLInputElement).value !==
          this.noteData?.title ||
        (document.querySelector("#text") as HTMLTextAreaElement).value !==
          this.noteData?.text
      ) {
        (document.querySelector(".submit-form") as HTMLElement).click();
      }
    },
    toggleEditing() {
      if (this.isEditing) {
        // save changes
        this.submitForm();
      }
      this.isEditing = !this.isEditing;
    },
    togglePin() {
      notes.togglePin(Number(this.$route.params.id));
    },
  },
  watch: {
    editable(newEditable) {
      if (newEditable) {
        const text = document.querySelector(".note-text") as HTMLElement | null;
        if (text) {
          setTimeout(function () {
            text.focus();
          }, 0);
        }
      }
    },
  },
});
