import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44432012"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "note-title" }
const _hoisted_2 = { class: "fa-solid fa-thumbtack" }
const _hoisted_3 = { class: "last-updated" }
const _hoisted_4 = { class: "preview-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: "note-item"
  }, [
    _createElementVNode("h2", _hoisted_1, [
      _createElementVNode("span", null, [
        _withDirectives(_createElementVNode("i", _hoisted_2, null, 512), [
          [_vShow, _ctx.note ? _ctx.note.pinned : '']
        ]),
        _createTextVNode(_toDisplayString(_ctx.note.title), 1)
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.lastUpdated), 1)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.previewText), 1)
  ]))
}