
import { defineComponent, PropType } from "vue";
import { notes } from "../notes";
import { Note } from "../types/custom-types.js";

export default defineComponent({
  name: "NoteForm",
  props: {
    noteData: Object as PropType<Note>,
    isEditing: Boolean,
  },
  emits: ["handle-form", "focus-input"],
  data() {
    return {
      title: this.noteData ? this.noteData.title : "",
      text: this.noteData ? this.noteData.text : "",
      id: this.noteData ? this.noteData.id : Number,
      lastUpdated: this.noteData ? this.noteData.lastUpdated : Date,
      pinned: this.noteData ? this.noteData.pinned : false,
    };
  },
  methods: {
    onSubmit() {
      const newNote: Note = {
        title: this.title,
        text: this.text,
        id: this.existingNote ? Number(this.id) : Date.now(), // keep same id if editing existing note
        lastUpdated: new Date(),
        pinned: this.pinned,
      };

      if (!this.existingNote) {
        notes.addNote(newNote); //add new note
      } else {
        notes.editNote(newNote); // edit existing note
      }

      this.$router.push(`${newNote.id}`);
    },
    focus(e: Event) {
      this.$emit("focus-input", e);
    },
  },
  computed: {
    existingNote() {
      return Boolean(this.noteData);
    },
  },
});
