import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e5a6fe2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.btn.class ? _ctx.btn.class : '', 'icon-button'])
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(['fa-solid', _ctx.btn.icon])
    }, null, 2),
    _createElementVNode("span", _hoisted_1, "  " + _toDisplayString(_ctx.btn.text), 1)
  ], 2))
}