import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_NotesList = _resolveComponent("NotesList")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      darkMode: _ctx.darkMode,
      onToggleDarkMode: _ctx.toggleDarkMode
    }, null, 8, ["darkMode", "onToggleDarkMode"]),
    _createVNode(_component_SearchBar, { onQueryChange: _ctx.setQuery }, null, 8, ["onQueryChange"]),
    _createVNode(_component_NotesList, { query: _ctx.query }, null, 8, ["query"]),
    _createVNode(_component_router_view)
  ], 64))
}