
import { defineComponent } from "vue";
import NoteItem from "./NoteItem.vue";
import { notes } from "../notes";
import { Note } from "../types/custom-types.js";

export default defineComponent({
  name: "NotesList",
  components: { NoteItem },
  props: {
    query: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notes,
    };
  },
  mounted() {
    notes.getLocalStorageNotes();
  },
  computed: {
    filteredNotes() {
      if (!this.query) {
        return notes.list;
      } else {
        return notes.list.filter(
          (note: Note) =>
            note.title.toLowerCase().includes(this.query) ||
            note.text.toLowerCase().includes(this.query)
        );
      }
    },
    pinnedNotes() {
      return this.filteredNotes.filter((note: Note) => note.pinned);
    },
    unpinnedNotes() {
      return this.filteredNotes.filter((note: Note) => !note.pinned);
    },
  },
});
