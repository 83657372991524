
import { defineComponent } from "vue";
import IconToggleButton from "./buttons/IconToggleButton.vue";
import HoverCircleButton from "./buttons/HoverCircleButton.vue";

export default defineComponent({
  name: "AppHeader",
  components: { IconToggleButton, HoverCircleButton },
  emits: ["toggle-dark-mode"],
  props: {
    darkMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      toggleIcons: {
        false: "fa-sun",
        true: "fa-moon",
      },
    };
  },
  methods: {
    openForm() {
      this.$router.push("/new");
    },
  },
});
